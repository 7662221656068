/*VERSION ESCRITORIO*/
.header {
  position: fixed;
  height: 10vh;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 500;
  transition: 0.3s ease-in;
}

.header-bg {
  background-color: var(--primary);
}

.header .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: auto;
  height: 100%;
  padding: 0 3rem;
}

.header .nav-menu a {

  font-weight: lighter;
  text-decoration: none;
}

.contenedorLogoNombre {
  color: #c9ff40;
  padding-left: 10px;
}

.header .navbar img {
  width: 230px;
  height: 110px;
}

.header .nav-menu {
  display: flex;
  list-style: none;
}

.header .nav-item {
  padding: 2rem;
}

.header .nav-item a:hover {
  padding-bottom: 12px;
  border-bottom: 3px solid var(--secondary);
}

.hamburger {
  text-align: right;
  display: none;
}

.nav_bl {
  color: white;
}

.closed .close {
  font-size: 40px;
}

.logoCamnet {
  max-height: 70px;
  max-width: 180px;
  padding: 10px 0 10px 30px;

}

.iconMenu {
  filter: invert(100%);
  height: 45px;
  width: 45px;
}

/*VERSION MOBILE-----------------------------------*/

@media (max-width: 1000px) {



  .header {
    max-width: 100%;
    height: 9vh;
    background-color: var(--primary);


  }

  .nav-menu {
    position: fixed;
    right: -100%;
    top: 9vh;
    flex-direction: column;
    background-color: var(--primary);
    width: 100%;
    height: 91vh;
    z-index: 999;
    text-align: center;
    justify-content: space-evenly;
    transition: 0.3s;




  }

  .header .nav-menu a {
    font-size: 3vh;

  }



  .header .navbar {
    max-width: 100%;
    padding: 0 1rem;
  }

  .contenedorNavbar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    background-color: var(--primary);



  }

  .hamburger {
    display: block;

  }

  .nav-menu li {
    min-width: 100%;

  }



  .nav-menu.active {
    right: 0;
  }

  .nav-item {
    line-height: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 0;

  }



  .header .navbar img {
    width: 150px;
  }

  .header .nav-menu a {
    text-decoration: none;
    color: aliceblue;
  }

  .slider ul li a {
    font-size: 20px;
    text-decoration: none;
    color: white;
  }

  .slider.active {
    top: 0;
    transition: 1s ease;

  }

  .logoCamnet {
    padding: 2vh;
    max-height: 90px;
    min-width: 200px;

  }

}