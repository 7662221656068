.services-container {
  background: white;
  padding: 0px 10% 100px 10%;
}

.services-title {
  width: 100;
  font-size: 48px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  grid-auto-rows: minmax(150px, auto);
}

@media (max-width: 1000px) {
  .services-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
