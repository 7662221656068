.stepper-container {
  padding: 20px;
}

.stepper-number {
  display: flex;
  align-items: center;
  width: 60px;
  height: 60px;
  background: var(--blue);
  border-radius: 8px;
  transition: 0.2s;
}

.stepper-number:hover {
  background: var(--primary);
  cursor: default;
}

@media (max-width: 1000px) {

  .stepper-container {
    padding: 20px 0;
  }
}
