.main-4-container {
  background: var(--background);
  padding: 15vh 10vw;
}

.main-4-subtitle {
  margin-top: 40px;
}

.main-4-grid {
  display: grid;
  margin-top: 40px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}

@media (max-width: 1000px) {
  .main-4-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .main-4-container {
    padding: 3vh 0vw;
  }

  .main-4-subtitle {
    margin-top: 20px;
  }
  .main-4-title {
    padding: 0 7vw;
  }

  .main-4-grid {
   
    margin-top: 10px;
    grid-gap: 10px;
    
  }
  

}
