.footprint-component-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 30px 10% 30px 10%;
  gap: 80px;
  background: var(--background);

}

.footprint-component-title {
  width: 100%;
  font-size: 30px;
}

.footprint-component-subtitle {
  width: 100%;
}

.footprint-component-img {
  width: auto;
  height: 100%;
  max-height: 300px;
}

.footprint-page-column-container {}

@media (max-width: 1000px) {

  .footprint-component-container {

    flex-direction: column;
    gap: 5px;
    flex-wrap: wrap;
    padding: 5vh 7vw;
  }

  .ordt {
    order: 2;
  }

  .ordim {
    order: 1;
  }

  .footprint-component-img {
    margin-top: 10px;
    width: 100%;
    height: auto;

  }
}