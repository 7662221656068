.service-card-container {
  background: white;
  padding: 36px;
  border-radius: 10px;
}

.service-img {
  height: 160px;
  transition: 0.2s;
}

.service-img:hover {
 transform: scale(1.1);
}

.service-card-title {
  font-size: 24px;
}



@media (max-width: 1000px) {
  .service-img {
    height: 120px;
    transition: 0.2s;
    
  }
}