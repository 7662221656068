.partners-container {
  background: none;
  padding-top: 0px;
  padding-bottom: 10px;
  width: 100%;
}

.partners-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 30px;
  grid-auto-rows: minmax(100px, 20px);
}

.partners-img {
  height: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

.negative {
  filter: grayscale(100%) invert(1) brightness(0.6);
}

.normal {
  background-color: var(--background);
}

.partners-our-partners {
  text-align: end;
}

@media (max-width: 1000px) {

  .partners-container {
    padding-bottom: 1px;
  
  }


  .partners-grid {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(80px, 0px);
    grid-gap: 5px;
    
  }

  .partners-img {
    height: 90px;
  }
  



}