.service-img-background {
  width: 100%;
  height: auto;
  margin-top: 20px;
  border-radius: 10px;
}

.service-img {
  max-height: 200px;
  width: 100%;
  border-radius: 10px;
}

.service-title {
  font-size: 30px;
}

.service-subtitle {
  margin-top: 20px;
  margin-bottom: 20px;
}
