.container-error404 {
    display: flex;
    height: 90vh;
    width: 100%;
    justify-content: space-between;
}

.container-image-error {
    position: relative;
    display: flex;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.image-error {
    width: 65%;
    z-index: 1;
    position: absolute;
    border-radius: 5px;
}

.container-error-mesage {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 80%;
    padding: 0vh 20vh 10vh 0vh;
    justify-content: center;
}
