.main-3-container {
    display: flex;
    flex-direction: column;
    padding: 4vh 12vw;

    background-color: white;

}

.title-main-3 {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.title-pry {
    font-size: 3vh;
}




.subpry {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}


.content-main-3 {
    display: flex;
    flex-direction: row;
}


.pry {
    position: relative;

}

.img-left,
.img-right {
    height: 100%;
}

.pry-2,
.pry-3 {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;

}

.pry-2 {
    position: relative;
    margin: 0vh 0vh 1vh 2vh;

}

.pry-3 {
    position: relative;
    margin: 1vh 0vh 0vh 2vh;

}

.left-gradient {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 4vh;
    position: absolute;
    width: 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    background: linear-gradient(345deg, rgba(28, 30, 83, 0.86) 40%, rgba(28, 30, 83, 0.18) 100%);
    color: white;
}


.right-gradient {

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    padding: 2vh 4vh;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    background: linear-gradient(345deg, rgba(28, 30, 83, 0.86) 40%, rgba(28, 30, 83, 0.18) 100%);
    color: white;
}


@media (max-width: 1000px) {

    .main-3-container {
        padding: 3vh 7vw;
    }

    .title-main-3,
    .subpry,
    .content-main-3 {
        flex-direction: column;
        flex-wrap: wrap;

    }

    .title-main-3 {

        padding: 2vh 0;
    }

    .title-pry {
        font-size: 2vh;
    }

    .pry-2 {
        margin: 4vh 0;

    }

    .pry-3 {
        margin: 0.5vh 0vh;
    }

    .left-gradient {
        width: 100%;
        margin: 0;
        margin-bottom: 4px;
        font-size: 1rem;
        padding: 1vh;
    }

    .right-gradient {
        width: 100%;
        height: 100%;
        margin: 0;
        margin-bottom: 4px;
        font-size: 1rem;
        padding: 1vh;
    }



    .button {
        margin: 1vh 0vh;
    }
}