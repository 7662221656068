.contact-container {
  width: 100%;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  align-content: center;
  margin-bottom: 5rem;
}

.top-container {
  display: flex;
  justify-content: center;
  transform: rotate(0deg);
}

.text-container {
  width: 60%;
}

.title-text {
  margin: 3rem 5rem;
  font-size: 5rem;
  font: large;
  font-weight: bold;
  color: white;
}

.form-description {
  margin: 0rem 5rem;
  margin-top: 2rem;
  font-size: 1.8rem;
  text-align: justify;
  text-justify: inter-word;
  color: white;
}

.form-container {
  width: 50%;
  display: block;
  padding: 3rem;
  border-radius: 20px;
  background: white;
}

.form-title {
  font-size: 2.4rem;
  font-weight: bold;
  color: var(--primary);
}

.form-label {
  font-weight: bold;
  margin-top: 1.2rem;
  font-size: 1.2rem;
  color: var(--primary);
}

.form-input {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.7rem;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  color: var(--primary);
}

.form-message {
  height: 10rem;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.7rem;
  padding: 20px;
  width: 100%;
  border-radius: 20px;
  color: var(--primary);
}

/*How to extend general button to specific css*/
.send {
  margin-left: -1px;
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .title-text {
    font-size: 3.2rem;
  }

  .form-description {
    font-size: 1.5rem;
  }

  .text-container {
    width: 100%;
  }

  .contact-container {
    margin-top: 5rem;
  }

  .form-container {
    width: 90%;
  }
}
