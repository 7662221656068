.main-6-container {
  background: var(--background);
  padding: 90px 10% 90px 10%;
}

.main-6-title {
  font-size: 38px;
}

.main-6-subtitle {
  font-size: 18px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .main-6-container {
    flex-direction: column;
  }
}
