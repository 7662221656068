.imgAboutUs {
    margin: 0 0 0 20vh;
    border-radius: 20px;
    width: 90%;
    filter: brightness(0.9);

}

.container-imgAbout {
    display: flex;
    justify-content: center;
    align-items: center;

}

.about-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 7vh;
    min-height: 95vh;
}


@media (max-width: 1000px) {


    .imgAboutUs {
        width: 100%;
        border-radius: 10vh;
        margin: 0;
        padding: 5vh; 
    }

    .list-characteristics {
        padding: 4vh 0 2vh 5vw;
        margin: 0;

    } 


}