.footprint-page-container {
  padding: 70px 0% 0px 0%;
  background: white;
}

.footprint-page-title {
  width: 100%;
  padding: 0px 10% 0px 10%;
}

.footprint-page-subtitle {
  width: 100%;

  padding: 0px 10% 0px 10%;
}

.footprint-component-container {

}

@media (max-width: 1000px) {
  .footprint-page-container {
    padding: 20px 0% 0px 0%;
  }

}