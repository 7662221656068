.footprint-container {
  width: 285px;
  margin-right: 32px;
  margin-top: 20px;
}

.footprint-img {
  height: 300px;
  width: auto;
  border-radius: 10px;
}

.footprint-date {
  width: 100%;
  font-size: 16px;
  margin-top: 40px;
}

.footprint-button-container {
  width: 100%;
}

.footprint-button {
  margin-top: 20px;
}

.footprint-title {
  width: 100%;
  font-size: 24px;
  margin-top: 16px;
}

.footprint-subtitle {
  width: 100%;
  font-size: 16px;
}

.footprint-read-more {
  font-size: 16px;
  margin-top: 12px;
  cursor: pointer;
}
