.faq-title-container {
  cursor: pointer;
  margin-top: 32px;
  
}

.faq-visible {
  display: block;
  transition: 2s;
}

.faq-invisible {
  display: none;
  transition: 2s;
}

.faq-icon {
  height: 16px;
  width: 16px;
  margin: auto;
}

.faq-enum {
  font-size: 24px;
  width: auto;
  margin: auto;
}

.faq-title {
  font-size: 3vh;
  margin: auto;
  margin-left: 20px;
  text-align: left;
}

.faq-text {
  font-size: 18px;
  text-align: justify;
  margin-top: 20px;
}

.faq-divider {
  margin-top: 48px;
}
