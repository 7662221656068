.container-ProjectCard {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    background-color: var(--background);
    padding: 10vh 11vw 2vh 11vw;

}

.list-characteristics-project {
    padding: 3vh;
}

.background-project {
    width: 100%;
    padding: 8vh 8vh;
    background: linear-gradient(345deg, rgba(28, 30, 83, 0.86) 40%, rgba(28, 30, 83, 0.18) 100%);
    border-radius: 10px;

}

.project-image {
    border-radius: 10px;
    max-height: 85vh;
}



@media (max-width: 1000px) {

    .container-ProjectCard {
        padding: 0vh 7vw 2vh 7vw;
    }

    .background-project {
        width: 100%;
        padding: 1vh 1vh 0.5vh 1vh;
    
    }

    .list-characteristics-project {
        padding: 1vh 3vh;
    }

    .titlep {
        order: 0;
    }

    .descriptionp {
        padding: 2vh 0;
        order: 2;
    }

    .listp {
        order: 3;
    }

    .imgp {
        order: 1;
        
    }

    .spacep{
        display: none;
    }
}