.main-8-container {
  background: var(--background);
  padding: 60px 10% 60px 10%;
}

.main-8-footprints {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

@media (max-width: 1000px) {
  .main-8-container {
    flex-direction: column;
  }

  .main-8-footprints {
    grid-template-columns: repeat(1, 1fr);
  }
}
