.footer-container {
  display: flex;
  background-color: var(--primary);
  min-height: 86vh;
  padding: 2vh 6vw;
}

.footer-logo {
  height: auto;
  width: 50%;

}

.footer-column-l {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0px 5%;
}

.footer-column-r {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0px 5%;

}



.footer-links {
  display: flex;
  width: 100%;
}

.footer-short-title {
  padding: 0.2vw;
}


.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--secondary);
  padding: 2vh;
}


.space-footer-cr {
  padding-right: 2rem;
}

.footer-social-icon {
  color: white;
}

.footer-copyright {
  display: flex;
  flex-direction: column;
  padding: 20px 11vw;
}

.map-camnet {
  padding: 1vh;
  background-color: var(--background);
}

.redes {
  display: flex;
  align-items: center;
  position: relative;
  max-width: 800px;
  max-height: 300px;


}


.social-network-list {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;

}

.social-network-list li {
  list-style: none;

}

.social-network-list li a .fab {
  transition: .5s;
}

.social-network-list li a {
  position: relative;
  display: block;
  width: 5vw;
  height: 5vw;
  margin: 2vh auto;
  background: var(--secondary-soft);
  text-align: center;
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0, 0);
  transition: .5s;
  box-shadow: -30px 30px 10px rgba(0, 0, 0, .5);
  border-radius: 5px;
}

.social-network-list li a:before {
  content: '';
  position: absolute;
  top: 15px;
  left: -25px;
  height: 95%;
  width: 20px;
  border-radius: 5px;
  background: var(--secondary);
  transition: .5s;
  transform: rotate(0deg) skewY(-45deg);
}

.social-network-list li a:after {
  content: '';
  position: absolute;
  bottom: -25px;
  left: -12px;
  height: 20px;
  width: 95%;
  border-radius: 5px;
  background: var(--secondary-soft);
  transition: .5s;
  transform: rotate(0deg) skewX(-45deg);
}

.social-network-list li a:hover {
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(20px, -20px);
  box-shadow: -50px 50px 50px rgba(0, 0, 0, .5);
}

.social-network-list li:hover .fab {
  color: white;
}

.social-network-list li:hover:nth-child(1) a {
  background: var(--facebook);
}

.social-network-list li:hover:nth-child(1) a:before {
  background: #5E77AB;
}

.social-network-list li:hover:nth-child(1) a:after {
  background: #4C68A2;
}

.social-network-list li:hover:nth-child(3) a {
  background: var(--twitter);
}


.social-network-list li:hover:nth-child(3) a:before {
  background: #64B2EE;
}

.social-network-list li:hover:nth-child(3) a:after {
  background: #73BAF0;
}


.social-network-list li:hover:nth-child(2) a {
  background: var(--instagram);
}

.social-network-list li:hover:nth-child(2) a:before {
  background: #E4506B;
}

.social-network-list li:hover:nth-child(2) a:after {
  background: #E7617A;
}

.social-network-list li:hover:nth-child(4) a {
  background: var(--apple);
}

.social-network-list li:hover:nth-child(4) a:before {
  background: #171717;
}

.social-network-list li:hover:nth-child(4) a:after {
  background: #2E2E2E;
}


@media (max-width: 1000px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    padding: 1vh 7vw;
  }

  .footer-logo {
    width: 90%;
    margin-bottom: 3vh;
  }

  .text-footer {
    padding: 1vh 0 3vh 0;
  }

  .footer-contact {
    flex-direction: column;
  }

  .footer-copyright {
    flex-direction: column;
  }

  .footer-links {
    flex-direction: column;
  }

  .footer-links a {
    padding-bottom: 0.5vh;
    text-align: center;

  }
  .map-camnet {
    padding: 0-5vh;
    min-height: 100px;
  }
  .spacemap{
    display: none; 
  }

  .redes {
    min-height: 150px;
  }

  .social-network-list {
    position: absolute;
    flex-direction: row;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }


  .social-network-list li a {
    width: 10vw;
    height: 10vw;
    margin: 2vh auto;
  }

}