.container-form {
    display: flex;
    flex-direction: row;
    min-height: 92vh;

}

.form-image {
    position: relative;
}

.left-gradient-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 4vh 8vw;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    background: linear-gradient(345deg, rgba(28, 30, 83, 0.86) 70%, rgba(28, 30, 83, 0.18) 100%);
    color: white;
}

.form-fields {
    padding: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    justify-content: center;
    align-items: center;
}

.form-title {
    padding: 2vh;
}

.text-field {
    padding-left: 2vh;
    border: 2px;
    border-style: double;
    border-color: var(--primary-soft);
    height: 10vh;
    margin-top: 10px;
    border-radius: 5px;
    background-color: var(--primary);
    outline: none;
}

.text-field:focus {
    background-color: var(--secondary-soft);
    color: var(--primary);
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.6);
}


.buttonn {
    width: 90%;
    border: none;
    height: 50px;
    display: flex;
    justify-content: left;
    border-radius: 28px;
    cursor: pointer;
    padding: 16px 31px 16px 0px;
    text-decoration: none;
    transition: 0.5s linear;
    color: var(--secondary);
    background-color: var(--primary);
    margin: 1vh;
}

.buttonn:hover {
    padding: 16px 18px;
    border-radius: 28px;
    width: 90%;
    height: 50px;
    color: black;
    background-color: var(--secondary);
    transition: 0.5s linear;
}


.text-field-error {
    padding-left: 2vh;
    border: 2px;
    border-style: double;
    border-color: red;
    height: 10vh;
    margin-top: 10px;
    border-radius: 5px;
    background-color: var(--primary);
    outline: none;

}

.text-field-error:focus {
    background-color: var(--secondary-soft);
    color: var(--primary);
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.6);
}


.text-field-pass {
    padding-left: 2vh;
    border: 2px;
    border-style: double;
    border-color: green;
    height: 10vh;
    margin-top: 10px;
    border-radius: 5px;
    background-color: rgb(7, 172, 81);
    outline: none;
}

.error-message {
    color: var(--secondary);
    width: 100%;
    text-align: center;
}



@media (max-width: 1000px) {
    .container-form {
        flex-direction: column;
    }

    .text-field-pass, .text-field-error {
        height: 5vh;
        margin-top: 20px;
    }

    .form-title {
        padding: 0 4vw;
    }

    .form-fields {
        padding: 0 4vw;
      
    }
}