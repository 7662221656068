.main-1-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0vh 10vw 0vh 10vw;
    align-content: space-between;
    min-height: 75vh;
}

.main-1-left {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 0 0 3vh;
}

.main-1-right {
    display: flex;
    padding: 0 0 0 2vh;
    align-items: center;
}

.main1-imagen-m {
    width: 100%;
    max-height: 100%;
    filter: brightness(0.8);
    animation: rotation 400s infinite linear;
    margin-left: 5vw;
}

.main1-imagen-s {
    width: 90%;
    max-height: 100%;
    filter: brightness(0.9);
}



@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}



.main-1-title {
    display: flex;
    min-height: 30vh;
    align-items: center;

}


.main-1-buttons {
    display: flex;
    min-height: 20vh;
    width: 100%;
    align-items: center;

}

.container-button-contact {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    justify-items: center;
    min-height: 15vh;
}

.logoCamnetMain {
    width: 70%;
}


@media (max-width: 1000px) {

    .main-1-container {
        flex-wrap: wrap-reverse;
        padding: 0;
 
    }

    .main-1-buttons {
     
        min-height: 15vh;
       
    
    }
        


    .main1-imagen-m {
        width: 120%;
    }

    .main-1-buttons {
        padding-top: 3vh;
        display: flex;
        flex-direction: column;
        align-content: center;

    }

    .main1-imagen-m {

        margin-left: 0;
    }

    .main-1-left {
        padding: 0 7vw 0 7vw;
    }


}