@font-face {
  font-family: nukaki;
  src: url("/src/Styles/Montserrat-ExtraLight.otf");
}

* {
  margin: 0px;
  padding: 0px;
  font-family: nukaki, sans-serif;
  width: 100%;
}

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background-color: var(--primary);
}

body::-webkit-scrollbar-thumb {
  background: var(--secondary);
  border: 5px solid transparent;
  border-radius: 9px;
  background-clip: content-box;
}

.splash-container-hidden {
  position: fixed;
  z-index: -1;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: var(--primary);
  transition: 1s linear;
  opacity: 0;
}

.splash-container-showed {
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: var(--primary);
  transition: 1s linear;
  opacity: 1;
}

@keyframes identifier {}

.animation {
  height: 50rem;
}

.App {
  z-index: 2;
  height: 100vh;
  width: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100%;
}

:root {
  --primary: #1c1e53;
  --primary-soft: #232564;
  --secondary: #fad967;
  --secondary-soft: #f7e4a2;
  --soft-text: #949494;
  --background: #f4f6fc;
  --blue: #0076bc;
  --divider: #ececf1;
  --peach: #ffad69;
  --white: #ffffff;
  --black: #000;
  --grey : #D3D3D3;
  --facebook: #3b5999;
  --twitter: #55acee;
  --instagram: #e4405f;
  --apple: #131418;
}


/************************************/
.button, button {
  width: 90%;
  height: 50px;
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 28px;
  cursor: pointer;
  padding: 16px 31px 16px 0px;
  text-decoration: none;
  transition: 0.5s linear;
  color: var(--secondary);
  margin: 1vh;
}

.button:hover, button:hover {
  padding: 16px 18px;
  border-radius: 28px;
  width: 90%;
  height: 50px;
  color: black;
  background-color: var(--secondary);
  justify-content: center;
  transition: 0.5s linear;
}

/************************************/


.link {
  text-decoration: none;
}

.center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}


.background-container {
  padding: 1vh;
  display: flex;
  flex-direction: column;
  background: var(--primary);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding-top: 10vh;
}

.cardView {
  border-radius: 35px;
  padding: 24px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}

.cardView:hover {
  box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.3);
}

.cardView,
.top-background {
  clear: both;
  position: relative;
  top: -10rem;
  right: 0;
  height: 45rem;
  width: 99%;
  transform: rotate(-4deg);
  background: linear-gradient(90deg,
      var(--primary) 0%,
      var(--primary-soft) 100%);
}

.background-test {
  background: red;
}

.divider {
  height: 2px;
  background: var(--divider);
}

.title {
  margin: 2vh 0vh;
  font-size: 6vh;
  font-weight: bolder;
  
}

.subtitle {
  font-size: 3vh;
  text-align: justify;
}



.center-text {
  text-align: center;
}

.left-text {
  text-align: left;

}

.white {
  color: white;
}

.black {
  color: black;
}

.blue-dark {
  color: var(--primary);
}

.blue {
  color: #0076bc;
}

.red {
  color: #ff0000;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.button-services {
  width: 70%;
  display: flex;
  justify-content: left;
  border-radius: 28px;
  cursor: pointer;
  color: var(--primary);
  font-size: 22px;
  padding: 16px 31px 16px 0px;
  text-decoration: none;
  transition: 0.5s linear;
  color: var(--primary);
}

.button-services:hover {
  padding: 16px 18px;
  border-radius: 28px;
  width: 100%;
  color: black;
  background-color: var(--secondary);
}

@media (max-width: 1000px) {

  .background-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
  }


  .button {
    max-height: 35px;
    width: 100%;
    margin-bottom: 1vh;

  }

  .button:hover {
    max-height: 35px;
    width: 100%;
  }

  .title {
    font-size: 3vh;
  }

  .subtitle {
    font-size: 2vh;
  }

}