.main-2-container {
  display: flex;
  background-color: var(--background);
  padding: 20vh 10vw 20vh 12vw;

}

.main-2-grid {
  display: grid;
  margin-left: 50px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(120px, auto);

}

.main-2-button {
  margin-top: 20px;
}

.main-2-button-container {
  width: 60%;
}

.main-2-btn-services {
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

@media (max-width: 1000px) {
  .main-2-container {
    flex-direction: column;
    padding: 5vh 7vw 5vh 7vw;
  }

  .main-2-grid {
    margin-left: 0px;
    grid-template-columns: repeat(1, 1fr);
    padding: 2vh 0vw 2vh 0vw;
    margin: 0;
  }

}