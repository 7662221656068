.container-clients {
    display: flex;
    flex-direction: row;
    padding: 4vh 10vw;
    background-color: white;

    align-items: flex-start;

}

.content-clients {
    display: flex;
    flex-direction: column;
}

.img-nav-client {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 20vh;
}


.coments-nav {
    display: flex;
    flex-direction: row;
}

.coments-nav-logo {
    margin: 1vh;
    padding: 0vh;
    transform: scale(1);
    max-height: 40px;

}

.coments-nav-logo:hover {
    transition: 0.5s;
    transform: scale(1.1);
}

.info-clients {
    padding: 1vh;
    margin: 0 1vw 0 3vw;

}

.picture-client {
    max-width: 100px;
    max-height: 100px;
    border-radius: 100%;
    margin-right: 2vw;
}

.coment-client {
    min-height: 20vh;
    padding: 1vh 1vh;
    margin: 1vh 0 0 0;
}

.clients-names {
    display: flex;
    flex-direction: column;
    margin-left: 0vw;
    max-width: 20vw;
    justify-content: center;
}

.client {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

@media (max-width: 1000px) {

    .container-clients,
    .img-nav-client,
    .client {
        flex-direction: column;
    }



    .client {
        align-items: center;
    }


    .clients-names {
        max-width: 90vw;
        height: 12vh;
        margin-bottom: 0vh;
    }

    .info-clients {
        margin: 0;
        padding: 0;

    }

    .coment-client {
        padding: 2vh 0vh;
        margin: 0vh 0;

    }

    .tm {
        text-align: center;
    }



    .picture-client {
        max-width: 160px;
        max-height: 160px;

    }

    .coment-client {
        min-height: 24vh;
    }



}